.background {
  background-image: url('../../images/header.jpg');
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-icon {
  color: '#D47DB2';
}

.sm-head-1 {
  color: #5e5e5e;
  font-size: 32px;
  word-spacing: 10px;
  letter-spacing: 4px;
  font-weight: bold;
}
