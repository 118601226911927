/* @import url('https://fonts.googleapis.com/css?family=Muli&display=swap'); */

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'HelveticaNeueLTStd Md';
  src: url('./fonts/HelveticaNeueLTStd\ Md.otf');
}

@font-face {
  font-family: 'HelveticaNeueLTStd Hv';
  src: url('./fonts/HelveticaNeueLTStd\ Hv.otf');
}

@font-face {
  font-family: 'HelveticaNeueLTStd Bd';
  src: url('./fonts/HelveticaNeueLTStd\ Bd.otf');
}

body {
  margin: 0;
  padding: 0;
}

h5,
h1,
h3,
h2,
h4,
h6 {
  font-family: 'HelveticaNeueLTStd Hv';
  color: #5e5e5e;
}

.head-div {
  font-family: 'HelveticaNeueLTStd Bd';
  color: #737373;
  font-size: 30px;
  letter-spacing: 2px;
}
p {
  font-family: 'HelveticaNeueLTStd Md' !important;
  font-size: 18px;
  color: #5e5e5e;
  line-height: 1.5em;
  word-spacing: 5px;
}

@media screen and (max-width: 350px) {
  .head-div {
    font-family: 'HelveticaNeueLTStd Bd';
    color: #737373;
    font-size: 20px;
    letter-spacing: 2px;
  }
}

.contact p {
  margin-bottom: 0px;
}
