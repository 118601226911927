.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus,
.btn-primary:active:focus {
  background-color: #d47db2 !important;
}

@font-face {
  font-family: 'HelveticaNeueLTStd Md';
  src: url('../../fonts/HelveticaNeueLTStd\ Md.otf');
}

.head-style {
  font-family: 'HelveticaNeueLTStd Md';
  background-color: #d47db2;
  color: #fff;
  font-size: 35px;
  padding: 20px;
  text-align: center;
  /* font-weight: bold; */
  width: 50%;
}

@media screen and (max-width: 350px) {
  .head-style {
    font-family: 'HelveticaNeueLTStd Md';
    background-color: #d47db2;
    color: #fff;
    font-size: 20px;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .head-style {
    font-family: 'HelveticaNeueLTStd Md';
    background-color: #d47db2;
    color: #fff;
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
}

.sm-head {
  color: #5e5e5e;
  font-size: 26px;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.foot-head-1 {
  color: '#737373';
  font-size: 38px;
  font-family: 'HelveticaNeueLTStd Bd';
  letter-spacing: 2px;
}

@media screen and (max-width: 350px) {
  .foot-head-1 {
    color: '#737373';
    font-size: 20px;
    font-family: 'HelveticaNeueLTStd Bd';
    letter-spacing: 2px;
  }
}
