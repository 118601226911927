@font-face {
  font-family: 'Helvetica Bold';
  src: url('../../fonts/Helvetica-Bold-Font.ttf');
}

.img-back {
  background-image: url('../../images/banner.png');
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.head-xs1 {
  font-family: 'Helvetica Bold';
  color: #837485;
  background-color: #f3e1ec;
  font-size: 32px;
  opacity: 0.8;
  width: 40%;
}

/* @media screen and (max-width: 700px) {
  .head-xs1 {
    color: #5e5e5e;
    background-color: gainsboro;
    font-size: 25px;
    width: 100%;
  }
} */

@media screen and (max-width: 1024px) {
  .head-xs1 {
    font-family: 'Helvetica Bold';
    color: #837485;
    background-color: #f3e1ec;
    font-size: 35px;
    width: 100%;
  }
}
